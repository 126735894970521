<template>
  <v-container class="pa-0">
    <v-row class="ma-0">
      <v-col cols="12" class="pa-0 ma-0">
        <v-img :src="require('../assets/introHeader2.png')" class="white--text align-end" height="639" cover></v-img>
      </v-col>
    </v-row>
    <v-row class="videoSection ma-0" v-scroll="handleScroll">
      <v-col cols="12" class="pa-0 ma-0 d-flex">
        <v-img :src="require('../assets/videoBg.png')" class="white--tex" height="682" position="top" cover>

          <video controls class="pa-8 video-player" ref="video" width="100%" playsinline muted>
            <source src="https://ignisshowreel.s3.eu-west-1.amazonaws.com/firefly-sizzle_reel_2024.mp4" type="video/mp4" />
          </video>

        </v-img>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',

  data: () => ({
    playing: 0
  }),

  mounted() {
    this.$refs.video
  },
  methods: {
    handleScroll: function () {
      if (window.scrollY >= 400) {

        if (this.playing == 0) {
          this.$refs.video.play();
          this.playing = 1;
        }

      }
      return window.scrollY > 450
    }
  }
}
</script>

<style lang="scss">
.v-card__title {
  word-break: break-word !important;
}

h1,
h2,
h3,
h6 {
  line-height: 1;
  letter-spacing: 1px;
}

h6 {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.video-player {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
}
</style>
